.hometitle{
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 30px;
  gap: 30px;
  flex-wrap: wrap;
  @media( min-width: 768px ){
    flex-wrap: nowrap;
  }
  &.center{
    text-align: center;
    justify-content: center;
  }
  &-main{
    position: relative;
    display: inline-block;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
    z-index: 2;
  }
}

.subpagetitle{
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 30px;
  &-main{
    position: relative;
    display: inline-block;
    // width: 100%;
    padding: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 800;
    color: $text-dark;
    z-index: 2;
  }
  &.text-center{
    text-align: center;
  }
}