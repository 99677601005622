.top-filters{
}


.sort-filter{
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

@media(max-width: 992px){
  #search_filters{
    // display: none;
    &.active{
      // display: flex;
    }
  }
}


#search_filters{
  display: none;
  // display: flex;
  align-items: end;
  align-content: end;
  flex-wrap: wrap;
  font-size: 14px;
  &.is-active{
    display: flex;
  }
  @media (min-width: 768px) {
    display: flex;
    grid-column: span 2 / span 2;
  }
}

.mobile-filter-button{
  padding: 10px 0;
  .btn{
    width: 100%;
  }
}



.products-sort-order{
  .select-title {
    position: relative;
    font-weight: 400;
    text-align: center;
    width: 140px;
    padding: 10px 20px 10px 15px;
    background: transparent;
    border: 1px solid $gray-darker;
    border-radius: 25px;

    svg {
      position: absolute;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      top: 0;
      right: 10px;
      width: 40px;
      height: 100%;
    }
  }
}

.facet{
  display: block;
  position: relative;
  width: 100%;
  // margin-right: 15px;
  // padding: 0 0 10px;
  // &:hover{
  //   .facet-box{
  //     display: block;
  //   }
  // }{
  &-filters{
    // display: none;
    display: flex;
    align-items: center;
    align-content: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    // gap: 20px;
    margin: 0 0 15px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #fafafa
    }

    &::-webkit-scrollbar-thumb {
      background: $brand-primary;
    }

    &::-webkit-scrollbar-thumb:hover {
      filter: brightness(0.8)
    }

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      // overflow: auto;
    }

  }
  &-title{
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px 0;
    margin: 0;
    // font-family: $secondary-font;
    font-weight: 600;
    color: $text-dark;
    cursor: pointer;
    svg{
      display: inline-block;
      width: 10px;
      height: auto;
      margin-left: 10px;
      color: $brand-primary;
    }
  }
  &-box{
    display: block;
    position: relative;
    background: #fff;
    padding: 10px 0 10px;
    z-index: 10;
    // @media (min-width: 768px) {
    //   position: absolute;
    //   min-width: 250px;
    //   padding: 10px 10px 10px;
    //   border: 1px solid $gray;
    //   overflow: auto;
    // }
    a{
      color: $text-dark;
    }
    &.open{
      display: block;
    }
  }
  &-list{
    padding: 0;
    margin: 0;
    max-height: 170px;
    overflow: auto;
  }
  &-clear{
    display: inline-flex;
    align-items: center;
    align-content: center;
    padding: 0;
    font-family: $secondary-font;
    font-weight: 500;
    text-decoration: underline;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    svg{
      width: 20px;
      height: auto;
      margin-left: 5px;
      color: $brand-primary;
    }

  }
  &-label{
    font-size: 14px;
  }
}


.faceted-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25, 25, 25, 0.5);
  z-index: 9999
}

.faceted-overlay .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute
}

.faceted-overlay .overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

.faceted-overlay .spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid
}

@keyframes spin {
  100% {
    transform: rotate(360deg)
  }
}

#search_filters .ui-slider-horizontal .ui-slider-handle {
  margin-left: -1px;
  cursor: pointer
}

#search_filters .ui-widget-header {
  background: $brand-primary;
}

#search_filters .ui-slider .ui-slider-handle {
  top: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: $brand-primary;
}

#search_filters .ui-slider-horizontal {
  height: .4em;
  width: 80%;
}

.active_filters {
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  background: $gray;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }

  ul {
    display: inline;
  }

  .filter-block {
    display: inline-block;
    padding: 0.625rem;
    margin-right: $small-space;
    margin-bottom: $small-space;
    font-size: $font-size-xs;
    color: $gray-darker;
    background: $white;

    .close {
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
      font-size: $font-size-lg;
      color: $gray-darker;
      opacity: 1;
    }
  }
}