.promo-time{
  position: relative;
  padding: 100px 0;
  margin: 0 -15px;
  background: url(../img/webp/counttime-bg.webp) no-repeat;
  background-size: cover;

  &-box{
    position: relative;
    padding: 10vmin;
    background: $white;
    border-radius: 25px;
  }
  &-info{
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  &-title{
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
  }
  &-countdown{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 20px;
    padding: 30px 0;
  }
  &-item{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: $gray;
    border-radius: 15px;
    .timer{
      font-size: 27px;
      font-weight: 800;
      color: $text-dark;
    }
    &:before{
      content: ':';
      position: absolute;
      font-size: 35px;
      font-weight: 600;
      color: $gray-darker;
      right: -15px;
    }
    &:last-of-type{
      &::before{
        display: none;
      }
    }
  }
  &-image{
    display: block;
    width: 100%;
    height: auto;
    margin: 50px 0 0;
    @media( min-width: 768px ){
      width: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0;
    }
  }
}