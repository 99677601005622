.subpage-wrapper {
  width: 100%;
  min-height: 70vh;
  padding: 0 15px 0;
}

.images{
  &-container{
  }
  &-grid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0 0 25px;
    @media(min-width: 786px){
      grid-template-columns: repeat(6, minmax(0, 1fr));
      grid-column-gap: 10px;
    }
  }
  &-thumbcontainer{
    position: relative;
    order: 2;
    @media(min-width: 786px){
      order: 1;
    }
  }
  &-thumbs{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding: 10px 0;
    @media(min-width: 786px){
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      padding-right: 10px;
      overflow-y: scroll;
      overflow-x: auto;
      scrollbar-width: none;
    }
    .thumb{
      width: 100%;
      margin: 0 0 10px;
      border: 2px solid transparent;
      &.is-active{
        border: 2px solid $brand-primary;
      }
      &-image{
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &-cover{
    position: relative;
    width: 100%;
    grid-column: span 5/span 5;
    order: 1;
    overflow: hidden;
    @media(min-width: 786px){
      order: 2;
    }
    img.zoom{
      position: absolute;
      max-width: none;
      background: $white;
      transition: width 0.2s ease-out, opacity 0.2s ease-out 0.2s;
    }
    img{
      display: block;
      width: 100%;
      height: auto;
      // border: 1px solid $gray;
    }
  }
}


.product-information {
  position: sticky;
  top: 15px;
  display: block;
  width: 100%;
  color: $gray-darker;

  .product-energy-class{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
    color: #1f1f1f;
    padding: 0 0 15px;
  }
}

.product-title {
  display: block;
  width: 100%;
  font-family: $secondary-font;
  font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
  font-weight: 800;
  color: $text-dark;
  padding: 0 0 15px;
  margin: 0;
  &-box{
    display: flex;
  }
}

.product-share{
  display: flex;
  align-items: center;
  align-content: center;
  min-height: 80px;
  padding: 15px 0;
  color: $text-dark;
}

.product-reference{
  padding: 0 0 15px;
  font-weight: 600;
  font-size: 16px;
  color: $text-dark;

}

.product-description {
  padding: 0 0 15px;
  font-size: 14px;
  line-height: 1.6;
  color: $text-dark;
}

.product-description,
.product-full-description{
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
    color: $text-dark;
    margin: 0 0 10px;
  }

  ul {
    list-style: inherit;
    list-style-position: inside;
    padding-left: .75rem;
  }

  table {
    width: 100%;
    margin: 25px 0;
    font-size: 12px;
    @media(min-width:786px){
      font-size: 15px;
    }
    tr {
      td {
        padding: 5px;
        text-align: center;
        justify-content: center;
        vertical-align: middle;
        border: 1px solid #eee;
      }
    }
  }
  h1,h2,h3,h4,h5{
    color: $brand-primary;
  }
  strong, b {
    // color: $brand-primary;
    // text-transform: uppercase;
  }

  iframe {
    max-width: 100%;
    min-height: 350px;
  }
}

.product-prices {
  padding: 0 0 15px;

  .tax-shipping-delivery-label {
    font-size: 0.8125rem;
    color: $gray;

    .delivery-information {
      padding: 0 0 0 2px;

      &::before {
        content: "-";
        padding: 0 2px 0 0;
      }
    }
  }
}

.product-price {
  color: $text-dark;
  display: block;
  margin: 0;
  padding: 0;
}

.current-price {
  display: block;
  font-size: 27px;
  font-weight: 800;
  color: $text-dark;
  &-value-tax{
    font-weight: 400;
    font-size: 16px;
    color: $text-dark;
  }
}

.product-discount {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: $gray-dark;
  text-decoration: line-through;
}

.product-without-taxes,
.product-discount .regular-price{
  font-size: 18px;
}

#product-description-short {
  color: $gray-darker;
}

.product-customization-item{
  textarea{
    display: block;
    width: 100%;
  }
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

.input-container {
  position: relative;
  label{
    margin: 0;
  }
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.radio-label {
  background: white;
  display: inline-block;
  width: 50px;
  padding: 10px;
  margin-right: 10px;
  font-weight: 400;
  text-align: center;
  border: 1px solid $gray;
}

.product-actions {

  padding: 0 0 15px;

  .control-label {
    display: block;
    padding: 0 0 10px;
    color: $text-dark;
    font-size: 16px;
    font-weight: 400;
  }
  .qty{
    padding-right: 15px;
  }
  .bootstrap-touchspin {
    // float: none;
    // width: 100%;
    // padding: 5px 10px;
    // background: $gray;
    padding: 4px;
    border: 1px solid $gray;
  }
  .btn-touchspin{
    border: none;
    background: $white;
    min-height: auto;
  }

  .add{
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px 0;
    gap: 20px;
    flex-wrap: wrap;
  }

  .add-to-cart {
    // margin: 25px 0;
    // margin-right: 10px;
    font-weight: 600;
    cursor: pointer;
  }
}

#product .product-quantity {
  display: flex;
  align-items: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 25px;

  input[type=number][name=qty].input-quantity{
    border: 1px solid $gray;
    &-select{
      min-width: 120px;
      height: 50px;
      margin-right: 15px;
      padding: 5px 15px;
      font-size:  18px;
      background-color: transparent;
      border-bottom: 1px solid $gray;
      // border-radius: 5px;
    }
  }

  input[type="number"][name="qty"] {
    width: 80px;
    padding: 5px 10px;
    font-size: 14px;
    color: $text-dark;
    background: $white;
    border: none;
    outline: none;
    // border: 1px solid $gray;
  }
  &-label{
    font-size: 14px;
    font-weight: 400;
    color: $text-dark;
  }
  &-input{
    display: flex;
    align-items: center;
    align-content: center;
    padding: 10px 15px;
    gap: 5px;
    border: 1px solid $gray-darker;
    border-radius: 25px;

    .quantity-minus,
    .quantity-plus{
      display: inline-block;
      height: 30px;
      cursor: pointer;
    }
  }
}

.product-discounts {
  // margin-bottom: 1.5rem;

  >.product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }

  >.table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: 0.3125rem $gray-light solid;
      text-align: center;
    }

    tbody tr {
      background: $gray-lighter;

      &:nth-of-type(even) {
        background: white;
      }

      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}

.product-availability {
  display: block;
  margin: 0 0 5px;
  font-size: 14px;
  color: $gray;
}

.product-discount {
  color: #6f6f6f;

  .regular-price {
    text-decoration: line-through;
    // font-weight: normal;
  }
}

.has-discount {

  &.product-price,
  p {
    // color: $brand-secondary;
  }

  .discount {
    display: inline-block;
    padding: 0.3125rem $small-space;
    margin-left: $small-space;
    font-weight: 600;
    font-size: $font-size-base;
    text-transform: uppercase;
    color: white;
    background: $brand-secondary;
  }
}

.product-unit-price {
  font-size: $font-size-xs;
  margin-bottom: 0;
}

.delivery-information {
  font-weight: 600;
}

.product-additional-info{
  padding: 10px 0;
  p{
    margin: 0;
  }
}


.tabs {
  margin-top: 2rem;
  background: $gray;
  border-radius: 15px;
  overflow: hidden;

  .nav-tabs {
    position: relative;
    padding: 25px 15px;
    text-align: left;
    border-bottom: none;
    white-space: nowrap;
    background: url(../img/webp/tab-bg.webp) no-repeat;
    background-size: cover;
    overflow-y: hidden;
    overflow-x: auto;
    border-radius: 15px;
    z-index: 2;

    .nav-link {
      position: relative;
      padding: 10px 15px;
      font-weight: 400;
      font-size: 14px;
      color: $white;
      text-align: center;
      background: transparent;
      border: 1px solid $white;
      border-radius: 25px;
      z-index: 3;

      &:focus,
      &:hover {
        color: $text-dark;
        background: $white;
      }

      &.active {
        color: $text-dark;
        background: $white;
      }
    }

    .nav-item {
      display: inline-block;
      float: none;
      padding: 0;
    }
  }

  .tab-content {
    // padding: 25px 0;

    .tab-pane{
      display: none;
      padding: 30px;
      background: $gray;

      .tab-pane-title{
        position: relative;
        margin: 0 0 30px;
        &-text{
          display: inline-block;
          position: relative;
          padding: 0;
          font-size: unquote("clamp(1.125rem, 0.4219rem + 2.25vw, 1.6875rem)");
          font-weight: 600;
          color: $text-dark;
          z-index: 2;
        }
      }
      &.active{
        display: block;
      }
    }
  }
}



@media(min-width: 992px) {
  .tabs {
    .nav-tabs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .nav-item {
        display: inline-flex;
      }
    }
  }
}

.product-attachments {
  // margin: 30px 0;

  .attachment{
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid $gray-darker;
    &:last-of-type{
      border-bottom: none;
    }
    &-name ,
    &-download{
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      align-content: center;
      gap: 10px;
      padding: 5px 20px;
      @media( min-width: 992px){
        padding: 5px 50px;
      }
    }
    &-name{
      justify-content: flex-end;
      border-right: 1px solid $gray-darker;
      svg {
        display: block;
        width: 18px;
        height: auto;
        margin: 0;
        color: $brand-primary;
      }

      h4 {
        font-size: 16px;
        margin: 0;
        padding: 0;
      }
    }
    &-download{
      justify-content: flex-start;
    }

  }
}

.product-manufacturer{
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0 0 15px;
  gap: 20px;
  color: $text-dark;
  .manufacturer-logo{
    display: block;
    width: auto;
    height: 60px;
  }
}

#product-modal {
  .modal-content {
    background: transparent;
    border: none;
    padding: 0;

    .modal-body {
      display: flex;
      margin-left: -30%;

      .product-cover-modal {
        background: white;
      }

      .image-caption {
        background: white;
        width: 800px;
        padding: 0.625rem 1.25rem;
        border-top: $gray-light 1px solid;

        p {
          margin-bottom: 0;
        }
      }

      .thumbnails {
        position: relative;
      }

      .mask {
        position: relative;
        overflow: hidden;
        max-height: 49.38rem;
        margin-top: 2.188rem;
        z-index: 1;

        &.nomargin {
          margin-top: 0;
        }
      }

      // .product-images {
      //   margin-left: $extra-large-space;

      //   img {
      //     width: 9.25rem;
      //     cursor: pointer;
      //     background: white;

      //     &:hover {
      //       border: $brand-primary 3px solid;
      //     }
      //   }
      // }

      // .arrows {
      //   height: 100%;
      //   width: 100%;
      //   text-align: center;
      //   position: absolute;
      //   top: 0;
      //   color: white;
      //   right: $large-space;
      //   z-index: 0;

      //   .arrow-up {
      //     position: absolute;
      //     top: -2rem;
      //     opacity: 0.2;
      //   }

      //   .arrow-down {
      //     position: absolute;
      //     bottom: -2rem;
      //   }

      //   i {
      //     font-size: 6.25rem;
      //     display: inline;
      //   }

      //   cursor: pointer;
      // }
    }
  }
}

#blockcart-modal {
  color: $gray-darker;

  .modal-header {
    background: white;

    .close {
      opacity: 1;

      .material-icons {
        color: $gray-dark;
      }
    }
  }

  .modal-body {
    background: $btn-warning-color;
    padding: 3.125rem 1.875rem;

    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0 .5rem;
      }
    }
  }

  .modal-dialog {
    max-width: 1140px;
    width: 100%;
  }

  .product-image {
    width: 100%;
    max-width: 9.375rem;
    display: block;
    margin: 0 0 0 auto;
  }

  .modal-title {
    color: #353943;
    font-size: 1rem;
    font-weight: 400;

    i.material-icons {
      margin-right: $large-space;
      color: $brand-success;
    }
  }

  .product-name {
    color: $brand-primary;
    font-size: 1.125rem;

    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    }
  }

  .product-price {
    color: $gray-dark;
    display: block;

    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    }
  }

  .cart-content {
    // padding-left: $extra-large-space;

    p {
      color: $gray-dark;
      display: flex;
      justify-content: space-between;
      padding: 0 .5rem;

      &.product-total {
        background-color: #D8D8D8;
        padding: .5rem;
      }

      &.cart-products-count {
        font-size: 1rem;
        color: $brand-primary;
        font-weight: 600;
      }

      &.product-tax {
        display: inherit;
        font-size: .875rem;
      }

      .label,
      .value {
        font-weight: 600;
      }
    }

    .cart-content-btn {
      display: inline-flex;

      button {
        margin-right: 0.9rem;
      }

      .btn {
        white-space: inherit;
      }
    }
  }

  .divide-right {
    border-right: 1px solid $gray-light-second;
  }
}

#product-availability {
  // margin-top: 0.625rem;
  padding: 5px 0;
  display: block;
  // font-weight: 700;
}

#product-details {
  .label {
    font-size: $font-size-base;
    color: $gray-darker;
    font-weight: bold;
  }
}

.product-short-features {
  padding: 30px 0 0;
  &-title{
    font-size: 18px;
    font-weight: 600;
    color: $text-dark;
  }
  &-list{
    border: 1px solid $gray;
    border-radius: 15px;
    overflow: hidden;
    @media( min-width: 768px){
      width: 45%;
    }
    >dl.data-sheet {
      display: block;
      padding: 25px;
      margin: 0;
      background: $gray;

      &:nth-child(2n){
        background: $white;
      }

      dd.value,
      dt.name {
        padding: 0;
        margin: 0;
        font-weight: 400;
        color: $text-dark;
      }

      dt.name {
        font-size: 14px;
      }

      dd.value {
        font-size: 16px;
      }
    }
  }

}

.product-features {
  >dl.data-sheet {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid $gray-darker;

    &:last-of-type {
      border-bottom: 0;
    }

    dd.value,
    dt.name {
      display: flex;
      flex: 1 0 50%;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      word-break: normal;
      margin: 0;
      padding: 5px 20px;
      @media( min-width: 992px){
        padding: 5px 50px;
      }
      // border-bottom:1px solid $gray;
    }

    dt.name {
      font-weight: 600;
      justify-content: flex-end;
      text-align: right;
      border-right: 1px solid $gray-darker;
    }

    dd.value {
      justify-content: flex-start;
      img {
        width: 100px;
        height: auto;
        image-rendering: pixelated;
      }
    }
  }
}

.quantity-none{
  display: block;
  padding: 10px;
  font-weight: 600;
  color: $brand-danger;
  text-decoration: uppercase;

}
.product-variants {
  >.product-variants-item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 20px;

    .control-label{
      padding: 0;
    }

    ul{
      margin: 0;
      padding: 0;
    }

    span.desc{
      position: relative;
      display: inline-block;
      margin: 0 5px;
      svg {
        width: 16px;
        vertical-align: middle;
        height: 16px;
        fill: #ff5634;
        margin: 0 auto;
        display: inline-block;
        line-height: 16px;
        // float: left;
      }
      .content {
          background: #333;
          color: #fff;
          font-size: 12px;
          padding: 4px 10px;
          border-radius: 3px;
          bottom: 100%;
          position: absolute;
          min-width: 250px;
          transform: translateX(-50%);
          left: 50%;
          opacity: 0;
          z-index: 100;
          margin-bottom: 8px;
          visibility: hidden;
          pointer-events: none;
          display: none;
      }
      &:after,
      &:before {
        content: "";
        bottom: 100%;
        position: absolute;
        margin-bottom: 0;
        left: 50%;
        visibility: hidden;
        pointer-events: none;
        display: none;
        margin-left: -4px;
        margin-bottom: 4px;
        width: 8px;
        height: 8px;
        background: #333;
        transform: rotate(45deg);
      }
      &:hover{
        svg{
          fill: #333;
        }
        &:after,
        .content {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    select {
      height: 45px!important;
      width: 200px;
      padding: 10px;
      margin: 0;
      background-color: transparent;
      border: 1px solid $gray-darker;
      border-radius: 25px;
      background-size: 25px;
      appearance: none;
      &:not([size]):not([multiple]){
        height: auto;
      }
    }

    ul li {
      // margin-right: $small-space;
    }

    .color {
      width: 27px;
      height: 27px;
      margin: 5px;
      border: 2px solid $gray;
      // box-shadow: 0px 0px 3px 2px #eee;
    }
    .input-container{
      &:hover{
        .color{
          outline: 2px solid $brand-primary;
        }
        .variant-tooltip {
          display: block;
        }
      }
    }
    .input-color{
      &:checked+span,
      &:hover+span {
        outline: 1px solid $brand-primary;
      }
    }
    .input-radio {
      &:checked+span,
      &:hover+span {
        color: $white;;
        background: $brand-primary;
      }
    }
  }
}

.product-flags {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  pointer-events: none;

  li.product-flag {
    pointer-events: auto;
    width: fit-content;
    padding: 8px 20px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    // font-style: italic;
    color: #fff;
    background: $brand-primary;
    border-radius: 25px;

    &.online-only {
      z-index: 1;
      font-size: $font-size-xs;
      margin-top: 0;

      &::before {
        content: "\E30A";
        font-family: 'Material Icons';
        vertical-align: middle;
        margin: 0.3125rem;
      }
    }

    &.discount-percentage,
    &.discount-amount,
    &.discount {
      background-color: $brand-secondary;
    }

    &.on-sale {
      // background: $brand-primary;
    }

    &.new {
      // background: $brand-secondary;
      // color: #fff;
    }
  }
}

.product-line-grid {}


.product-pack {
  margin-top: $extra-large-space;

  .pack-product-container {
    display: flex;
    justify-content: space-around;

    .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: $gray;
    }

    .pack-product-quantity {
      border-left: $gray-light 2px solid;
      padding-left: $small-space;
    }

    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  ul {
    margin-bottom: 0;
  }

  li {
    height: 2.5rem;
    width: 2.5rem;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: 0.25rem 0.25rem;
    cursor: pointer;
    margin-left: 0.5rem;
    @include transition(all .2s ease-in);

    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;

      &:hover {
        color: transparent;
      }
    }
  }
}

#blockcart-modal .cart-content {
  .btn {
    margin-bottom: $small-space;
  }
}

.product-accessories {
  margin: 5vmin 0 0;
}

.related-title {
  display: block;
  margin: 15px 0 25px;
  font-size: 24px;
}

.category-products{
  margin: 0 -15px;
  padding: 10vmin 0 0;
  &-title{
    padding: 0 0 25px;
    &.center{
      text-align: center;
    }
    &-text{
      position: relative;
      display: inline-block;
      font-size: 22px;
      font-weight: 600;
      color: $text-dark;
      &::after{
        content:'';
        display: block;
        height: 2px;
        width: 200px;
        margin: 10px auto;
        background: $brand-primary;
      }
    }
  }
}

@media(min-width: 992px) {}

.wishlist-button-add i {
  color: $brand-primary !important;
}
@media(max-width: 992px){
  .wishlist-button-product{
    margin: 20px 0!important;
  }

}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }

  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;

    img.product-cover-modal {
      width: 100%;
    }

    .arrows {
      display: none;
    }
  }

  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }

  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: 1.875rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    .modal-body {
      padding: 1rem;
    }
  }
}

#product{
  .inpostizi-bind-button {
    max-width: 100% !important;
  }
}
