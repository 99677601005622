.homepagetext{
  padding: 10vmin 0 0;
  margin: 0 -15px;
  background: $gray;

  &-title{
    position: relative;
    display: block;
    padding: 0 0 30px;
    margin: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
  }

  &-info{
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 30px;
    @media( min-width: 768px ){
      flex-wrap: nowrap;
    }
    &-border{
      flex: none;
      position: relative;
      width: 200px;
      height: 200px;
      padding: 20px;
      background: $gray;
      border: 1px solid $brand-primary;
      border-radius: 100%;
      &-text{
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        font-weight: 600;
        color: #fff;
        background: $brand-primary;
        border-radius: 100%;
      }
    }
  }

  &-grid{
    display: grid;
    gap: 30px;
    padding: 30px 0 100px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      grid-template-columns: repeat(2, minmax(0,1fr));
    }

    @media(min-width: 1024px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1280px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1439px){
      // grid-template-columns: repeat(5, minmax(0,1fr));
    }

    &-item{
      &-up{
        display: block;
        font-weight: 800;
        font-size: unquote("clamp(1.125rem, -0.5938rem + 5.5vw, 2.5rem)");
        color: $brand-primary;
        line-height: 1;
      }
      &-title{
        display: block;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 600;
      }
      &-desc{
        font-size: 14px;
        font-weight: 400;
        color: $text-dark;
        p{
          font-size: 14px;
          font-weight: 400;
          color: $text-dark;
          margin: 0;
        }
      }
    }
  }

  &-product{
    display: block;
    padding: 30px;
    text-align: center;
    color: $white;
    background: url(../img/webp/about-bg.webp) no-repeat fixed;
    background-size: cover;
    border-radius: 25px;
    @media( min-width: 768px ){
      padding: 100px;
    }
    &-subtitle{
      display: block;
      font-size: 20px;
    }
    &-title{
      display: block;
      padding: 15px 0;
      font-weight: 800;
      font-size: unquote("clamp(1.125rem, -0.5938rem + 5.5vw, 2.5rem)");
      line-height: 1;
    }
    &-btn{
      padding: 15px 0 30px;
    }
    &-image{
      display: block;
      margin: 0 auto;
      @media( min-width: 768px ){
        margin: 0 auto -170px;
      }
    }
  }
}



.homepagetextsec{
  padding: 10vmin 0;
  margin: 0 -15px;
  background: $gray;

  &-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      gap: 100px;
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
  }

  &-title{
    position: relative;
    display: block;
    padding: 0 0 30px;
    margin: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
  }

  &-desc{
    display: block;
    padding: 0 0 30px;
    font-size: 16px;
    font-weight: 400;
    color: $text-dark;
    line-height: 1.6;
    p{
      font-size: 16px;
      font-weight: 400;
      color: $text-dark;
      margin: 0;
    }
  }

  &-box{
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
    padding: 0;
    margin: 30px 0 0;
    background: url(../img/webp/info-bg-gradient.webp) no-repeat;
    background-size: cover;
    border-radius: 25px;
    overflow: hidden;
    @media(min-width: 768px){
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
    &-image{
      padding: 0;
      margin: 0;
      img{
        display: block;
        height: 100%;
        width: auto;
      }
    }
    &-text{
      display: flex;
      align-items: center;
      align-content: center;
      color: #fff;
      padding: 15px;
      font-size: 14px;
      p{
        font-size: 14px;
        margin: 0;
        color: #fff;
      }
    }
  }

  &-info{
    &-1{

    }
    &-2{
      display: flex;
      align-items: flex-end;
      align-content: flex-end;
      flex-wrap: wrap;
      padding: 80px 30px 30px;
      background: url(../img/webp/info-bg.webp) no-repeat fixed;
      background-size: cover;
      border-radius: 25px;
      overflow: hidden;
    }
  }

  &-bottom{
    &-box{
      display: block;
      padding: 15px;
      text-align: center;
      background: $white;
      border-radius: 25px;

      &-avatar{
        width: 120px;
        height: 120px;
        padding: 10px;
        margin: -70px auto 0;
        background: #fff;
        border-radius: 100%;
      }
      &-image{
        display: block;
        padding: 0;
        margin: 0;
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
      &-desc{
        padding: 15px 0 0;
        line-height: 1.6;
        font-size: 14px;
        p{
          font-size: 14px;
        }
      }
    }
  }
}



.homepage-faq{
  padding: 10vmin 0 0;

  &-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      gap: 100px;
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
  }

  &-title{
    position: relative;
    display: block;
    padding: 0 0 30px;
    margin: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
  }

  &-desc{
    display: block;
    padding: 0 0 30px;
    font-size: 16px;
    font-weight: 400;
    color: $text-dark;
    line-height: 1.6;
    p{
      font-size: 16px;
      font-weight: 400;
      color: $text-dark;
      margin: 0;
    }
  }
  &-list{
    padding: 0 0 30px;
  }

  &-btn{
  }

  &-item{
    display: block;
    margin: 0 0 25px;
    border-radius: 15px;
    background: $gray;

    &-question{
      display: flex;
      align-items: center;
      align-content: center;
      padding: 15px;
      gap: 15px;
      cursor: pointer;

      &-mark{
        flex: none;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-weight: 700;
        font-size: 27px;
        color: $white;
        background: $brand-primary;
        border-radius: 100%;
        text-align: center;
      }
    }

    &-answer{
      padding: 15px 25px 25px;
    }
  }

  &-image{
    display: flex;
    text-align: center;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: center;
    margin: 0;
    padding: 0;
    img{
      flex: none;
      display: inline-block;
      width: 70%;
      height: auto;
    }
  }
}


.homepagetextthird{
  padding: 10vmin 0 ;
  margin: 0 -15px;
  background: $gray;

  &-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      gap: 100px;
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
  }

  &-title{
    position: relative;
    display: block;
    padding: 0 0 30px;
    margin: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 600;
    color: $text-dark;
  }

  &-desc{
    display: block;
    padding: 0 0 30px;
    font-size: 16px;
    font-weight: 400;
    color: $text-dark;
    line-height: 1.6;
    p{
      font-size: 16px;
      font-weight: 400;
      color: $text-dark;
      margin: 0;
    }
  }

  &-icons{
    padding: 0 0 30px;
    &-item{
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      gap: 20px;
      padding: 0 0 30px;

      &-icon{
        flex: none;
        display: block;
        margin: 0;
        padding: 0;
        img{
          display: block;
          width: 100px;
          height: auto;
        }
      }
      &-text{
        strong{
          display: block;
          line-height: 1;
          font-size: 20px;
          color: $text-dark;
        }
      }
    }
  }

  &info{
    &-1{

    }
    &-2{
      &-image{
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}