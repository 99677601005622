.footer-social {
  padding: 0 0 50px;
  .social-footer{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    // gap: 5px;
  }
}

.footer-title {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin: 0 0 25px;
}

.footer-container {
  padding: 50px 0;

  li {
    margin: 15px 0;
  }

  li a {
    font-size: 14px;
    font-weight: 400;
    color: $text-dark;

    &:hover {
      color: $brand-primary;
    }
  }
  .contact {
    &-list{
      &-item{
        display: flex;
        align-items: center;
        align-content: center;
        padding: 10px 0;
        gap: 15px;
        svg{
          display: block;
          width: 15px;
          height: auto;
          color: $brand-primary;
        }
        &-text{
          font-weight: 400;
          font-size: 14px;
          color: $text-dark;
          a{
            color: $text-dark;
            font-weight: 400;
          }
        }
      }
    }
  }
}




.footer-bottom {
  font-size: 13px;
  color: $white;
  background: $brand-primary;
  // border-top: 1px solid $gray;

  a {
    color: $white;
  }

  .container {
    padding: 15px 15px;
  }
  .copyright{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    @media(min-width: 768px){
      justify-content: flex-start;
    }
    svg{
      display: inline-block;
      margin-right: 10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    box-shadow: none;
    margin-top: 0;
    .footer-title{
      margin: 0 0 15px;
    }
    .links {
      margin: 0 0 25px;
      ul {
        margin-bottom: 0;

        >li {
          padding: 5px 0;
        }
      }
    }
  }
}