.menu {
  margin-bottom: 0;
}

.top-menu {
  > .category{
    // position: relative;
  }
  &[data-depth="1"] {
    // display: flex;
    // align-items: start;
    // align-content: start;
    // justify-content: start;
    // padding: 10px 0;
    margin: 0;
    // flex-wrap: wrap;
    // gap: 10px;
  }

  &[data-depth="0"] {
    margin-bottom: 0;
  }

  a:not([data-depth="0"]) {
    display: block;
    padding: 0.625rem;
    font-weight: 400;
    color: $text-dark;
  }

  .dropdown-item{
    word-break: break-all;
    white-space: normal;
  }

  a.dropdown-submenu {
    font-weight: 400;
    color: $text-dark;
    // text-transform: uppercase;
  }

  a[data-depth="0"] {
    padding: 20px 10px;
    font-size: 14px;
    font-weight: 400;
    color: $text-dark;
    @media(min-width: 768px){
      font-size: 14px;
    }
    @media(min-width: 1440px){
      // font-size: 16px!important;
    }

    &:hover {
      color: $brand-primary;
      #header & {
        // color: $brand-primary;
        text-decoration: none;
      }
    }
  }

  a[data-depth="1"],
  a[data-depth="2"] {
    padding: 5px 15px;
    background-color: $gray;
    img{
      display: block;
      width: 50px;
      height: auto;
      margin-right: 10px;
    }
    &:hover{
      border-color: $brand-primary;
    }
  }

  .collapse {
    // display: inherit;
  }

  .sub-menu {
    z-index: 18;
    width: 300px;
    left: 0;
    top: 60px !important;
    background-color: $gray;
    border: 0;
    border-radius: 0;
    // border-top-left-radius: 0;
    // border-top-right-radius: 0;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    transition: opacity .5s ease, visibility .5s ease;
    // overflow: hidden;

    &.collapse {
      visibility: hidden;
      opacity: 0;

      &.in {
        visibility: visible;
        opacity: 1;
      }
    }

    ul[data-depth="1"]>li {
      width: 300px;
      padding: 5px 0;
      background: $gray;
    }

    a:hover {
      color: $brand-primary;
    }
  }

  .popover {
    max-width: inherit;
    // border-radius: 0;
  }

  &[data-depth="2"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 300px;
    width: 300px;
    height: 100%;
    background: #fff;

    .dropdown-item {
      background: #fff;
    }
  }
  &[data-depth="1"]{
    .category {
      &:hover {
        .collapse{
          display: block;
        }
        .top-menu[data-depth="2"] {
          opacity: 1;
        }
      }
    }
  }

}

.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#_desktop_top_menu {
  .top-menu {
    &[data-depth="0"] {
      li {
        &:hover {
          .sub-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

#mobile_top_menu_wrapper {
  padding: 15px;
  // background: $white;

  #top-menu {
    margin-bottom: 0.625rem;
  }

  .top-menu {
    color: $text-dark;

    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .collapse-icons .remove {
      display: none;
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }

    a[data-depth="0"] {
      padding: 0.625rem;
      font-size: 16px;
      color: $text-dark;
      border-bottom: 1px solid $gray-lighter;
    }

    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .sub-menu {
      position: static;
      z-index: inherit;
      display: block;
      width: 100%;
      min-width: 100%;
      margin-left: 0;
      overflow: hidden;
      background: $white;
      box-shadow: none;

      &.collapse {
        display: none;

        &.in {
          display: block;
        }
      }

      ul[data-depth="0"]>li {
        border-bottom: 1px solid $gray;
      }

      ul[data-depth="1"] {
        margin: 0;

        >li {
          margin: 0;
          width: 100%;

          a {
            text-transform: none;
          }
        }
      }

      ul {
        padding: 0;
      }

      li>a {
        padding: 0.625rem;
        font-weight: 400;
        color: $text-dark;
        border-bottom: 1px solid $white;
      }

      ul[data-depth="2"] li a {
        padding-left: 1.25rem;
      }

      ul[data-depth="3"] li a {
        padding-left: 2.5rem;
      }

      ul[data-depth="4"] li a {
        padding-left: 3.75rem;
      }
    }
  }

  .js-top-menu-bottom {
    a {
      color: $text-dark;
    }

    .language-selector-wrapper {
      padding: 0.625rem;

      .language-selector {
        display: inline;
      }
    }

    .currency-selector {
      padding: 0.625rem;
    }

    #contact-link {
      padding: 0.625rem;
    }

    .user-info {
      padding: 0 0.625rem;

      a {
        display: block;
        width: 100%;
        padding: 0.625rem 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .top-menu {
    .sub-menu {
      visibility: visible;
      opacity: 1;
      transition: 0.5s ease-out;
    }
  }
}
