.slider-homepage{

  overflow: hidden;
  background: url(../img/webp/slider-bg.webp) no-repeat;
  background-size: cover;
}
.slider{
  margin: 0 -15px;

  &-item{
    padding: 0;
    margin: 0;
    position: relative;
    display: grid;
    gap: 20px;
    position: relative;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
  }

  &-image{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    @media(min-width: 768px){
      order: 2;
    }
    &::before{
      content: '';
      display: block;
      width: 35vmin;
      height: 35vmin;
      margin: auto;
      background: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 100%;
      z-index: 1;
    }
    img.image{
      position: relative;
      display: block;
      width: 60%;
      height: auto;
      margin: 0 auto;
      z-index: 2;
    }
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: start;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 25px;
    z-index: 2;
    @media ( min-width: 768px ) {
      order: 1;
    }
  }

  &-title {
    padding: 0 0 25px;
    margin: 0;
    font-family: $secondary-font;
    font-size:  27px;
    font-weight: 600;
    // letter-spacing: 1px;
    color: $white;
    word-break: break-word;
  }

  &-description {
    display: block;
    width: 100%;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 25px;
    line-height: 1.2;
    p{
      margin: 0;
      color: $white;
      font-size: 16px;
      font-weight: 400;
    }
    b, strong{
      font-size: 16px;
      font-weight: bold;
      color: $white
    }
  }

  &-buttons{
    display: flex;
    gap: 20px;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 30px 0 0;
  }
}