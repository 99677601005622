#header {
  display: contents;
  position: relative;
  z-index: 99;
  color: $text-dark;
  background: $white;

  .header-nav{
    &-1,
    &-2{
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      @media ( min-width: 768px ) {
        flex-wrap: nowrap;
      }
    }

    &-1{
      justify-content: flex-start;
    }

    &-2{
      justify-content: flex-end;
    }

    &-menu{
      display: none;
      padding: 10px 15px;
      @media( min-width: 768px ){
        display: block;
      }
      &-list{
        display: flex;
        align-items: center;
        align-content: center;
        list-style: none;
        gap: 15px;
        margin: 0;
        padding: 0;
        li{
          padding: 0;
          a{
            color: $text-dark;
            &:focus,
            &:hover{
              color: $brand-primary;
            }
          }

        }
      }
    }
  }

  .contact-link{
    padding: 10px 0;
    a {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      color: $text-dark;
      svg {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        color: $brand-primary;
      }
    }
  }

  .language-selector{
    .expand-more{
      display: inline-flex;
      align-items: center;
      align-content: center;
    }
    .expand-more-flag{
      width: 13px;
      height: 13px;
      margin-right: 10px;
      border-radius: 999px;
    }
  }

  .header-top{
    padding: 10px 0;
    background-color: $gray;
    &-content{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      @media (min-width: 768px) {
        justify-content: space-between;
      }
      &-blocks{
        display: flex;
        align-items: center;
        align-content: center;
        gap: 10px;
      }
    }
  }

  .header-btn {
    position: relative;
    display: none;
    flex: none;
    text-align: center;
    cursor: pointer;
    @media (min-width: 768px) {
      display: block;
    }
    a {
      cursor: pointer;
    }

    .header-link {
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      color: $text-dark;
      font-size: 14px;
    }

    .header-icon {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 7px;
      background-color: $brand-secondary;
      transition: .2s all ease-in-out;
      border-radius: 999px;
      svg{
        flex: none;
        display: block;
        width: 15px;
        height: auto;
        color: $white;
      }
      &:hover{
        a, svg{
          color: $white!important;
        }
      }
    }

    .header-name {
      font-size: 14px;
      color: $text-dark;
    }


    &:hover {
      .header-icon {
        svg {
          color: $white;
        }
      }
    }

    .cart-products-count {
      font-size: 12px;
      color: $white;
    }
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // @media(min-width: 768px){
    //   overflow-x: auto;
    // }

    &-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

    }

    >ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      text-align: center;

      >li {
        position: relative;
        display: inline-block;
        margin: 0 10px;

        &.home-main {
          svg {
            display: block;
            width: 20px;
            color: #fff;
          }
        }

        &.category-main {
          >a {
            display: flex;
            color: #fff;
            align-items: center;

            svg {
              width: 20px;
              height: auto;
              margin-right: 10px;
            }
          }

        }

      }
    }
    >ul[data-depth="0"] {
      >li {
        &:hover {
          >a {
            color: #fff;
            background: $brand-primary;
          }
        }
      }
    }
  }

  .mobile{
    &-navs{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }

    .contact-link{
      padding: 10px;
    }

    .header-icon{
      padding: 10px;
      color: $text-dark;
      svg{
        width: 20px;
        height: auto;
      }
    }
    .header-name{
      display: none;
    }
  }
}