#wrapper {
  #content-wrapper,
  &.wrapper-subpage {
    width: 100%;
    padding: 0 15px;

    .main-content{
      padding: 50px 0;
      margin: 0 -15px;
    }

    // padding: 2.5rem 0 0;
    // background: #eef1f6;
    // border-bottom: 2px solid $brand-secondary;

    // @media(min-width:992px){
    //   .container,
    //   .container-fluid{
    //     padding: 0 35px;
    //   }
    // }
  }
  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }


}


.subpage-wrapper {
  width: 100%;
  min-height: 50vh;
  padding: 0 15px 0;

}

#pagenotfound{
  .subpage-wrapper{
    padding: 40px 15px 0;
  }
}

.breadcrumbs-wrapper {
  width: calc( 100% + 30px );
  padding: 0 15px;
  margin: 0 -15px;
  background: url(../img/webp/breadcrumb-bg.webp) no-repeat;
  background-size: cover;

  .breadcrumb {
    display: block;
    width: 100%;
    padding: 50px 0;
    margin: 0;
    font-size: 14px;
    color: $white;
    background: transparent;

    &[data-depth="1"] {
      display: none;
    }


    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        margin: 0.3125rem;
        color: $white;
        content: "/";
      }

      &:last-child {
        margin: 0;
        font-weight: 400;
        color: $white;
        content: "/";

        &::after {
          content: "";
        }
      }

      a {
        color: $white;
      }
    }
  }
}


#category{
  .breadcrumbs-wrapper{
    .breadcrumb{
      display: block;
      padding: 50px 0 150px;
    }
  }
  #wrapper{
    .main-content{
      padding: 0 0 50px;
    }
  }
}

#contact{
  .breadcrumbs-wrapper{
    .breadcrumb{
      padding: 50px 0 150px;
    }
  }
  #wrapper{
    .main-content{
      padding: 0 0 50px;
    }
  }
}
#cms.cms-id-4{
  .breadcrumbs-wrapper{
    .breadcrumb{
      padding: 50px 0 150px;
    }
  }
  #wrapper{
    .main-content{
      padding: 0 0 50px;
    }
  }
}