#products {
  color: $text-dark;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin-bottom: 1rem;

    .btn-secondary {
      color: $gray;
      text-transform: inherit;

      .material-icons {
        margin-right: 0;
      }
    }
  }
}

.block-category {
  position: relative;
  margin: 0 0 30px;
  &-info{
    position: relative;
    padding: 25px;
    margin: 0 0 50px;
    border-radius: 25px;
    background: $white;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);;
    transform: translateY(-100px);
    margin: 0 0 -30px;
    z-index: 10;
    @media( min-width: 768px ){
      padding: 45px;
    }
    &.with-image{
      display: flex;
      align-items: center;
      align-content: center;
      gap: 40px;
      flex-wrap: wrap;
      @media( min-width: 768px ){
        flex-wrap: nowrap;
      }
    }
  }
  &-title{
    padding: 0 0 15px;
    &-text {
      display: block;
      position: relative;
      font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
      font-weight: 800;
      color: $text-dark;
      // letter-spacing: 2.7px;
      margin: 0;
      padding: 0;
    }
  }

  &-image {
    flex: none;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 200px;
    height: 200px;
    padding: 30px;
    box-sizing: border-box;
    background: $gray;
    border-radius: 100%;
    overflow: hidden;
    z-index: 9;
    img {
      width: 100%;
      height: auto;
    }
  }

  &-description {
    padding: 15px 0 15px;
    line-height: 1.6;
    color: $text-dark;

    p {
      color: $text-dark;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    &.bottom{
      font-size: 14px;
      p{
        font-size: 14px;
      }
    }
  }
}

#category{
  .products{
    @media(min-width: 1024px){
      grid-template-columns: repeat(3, minmax(0,1fr));
    }

    @media(min-width: 1280px){
      grid-template-columns: repeat(3, minmax(0,1fr));
    }
  }
}

#category-description{
  padding: 20px 0;
  font-size: 14px;
  line-height: 1.6;
  p{
    margin: 5px 0;
  }
  h1,h2,h3,h4,h5,h6{
    font-weight: 600;
    color: $text-dark;
  }
  h1{
    font-size: 28px;
  }
  h2{
    font-size: 25px;
  }
  h3{
    font-size: 22px;
  }
  h4{
    font-size: 19px;
  }
  p{
    font-size: 17px;
    margin: 15px 0;
  }
  ul{
    list-style: disc;
    list-style-position: inside;
    &::marker{
      color: $brand-primary;
    }
  }
  ol{
    list-style: decimal;
    list-style-position: inside;
  }
}

.products-selection {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  color: $text-dark;
  padding: 0 0 10px;
  gap: 15px;
  @media(min-width: 576px){
    justify-content: end;
    align-items: end;
  }
  .sort-by-row {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 10px;
  }

  .filter-button{
    width: 100%;
  }

  .sort-by,
  .result-per-page {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }

  @media(min-width: 992px){
    .sort-by{
    }

  }

  .sort-by-text{
    padding-right: 10px;
    font-weight: 600;
  }

  .total-products {
  }

  h1 {
    padding-top: 0.625rem;
  }

  p,
  .sort-by-row {
    &,
    .sort-by,
    .select-title {
      position: relative;
      text-align: right;

      svg{
        width: 10px;
        margin-left: 10px;
      }
      img {
        display: inline-block;
        width: 30px;
        height: auto;
        margin: -1px 3px 0;
        line-height: inherit;
      }
    }
  }

  .filter-button {
    .btn {
      width: 100%;
      padding: 0.75rem 0.5rem 0.6875rem;
      text-align: center;
      justify-content: center;
    }
  }
}

.products-sort-order,
.brands-sort,
.suppliers-sort {
  color: $gray;

  .select-title {
    display: inline-flex;
    align-items: center;
    align-content: center;
    width: 100%;
    max-width: 200px;
    color: $text-dark;
    cursor: pointer;
    background: $white;
    border: none;
  }

  .select-list {
    display: block;
    padding: $small-space $medium-space;
    color: $text-dark;

    &:hover {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
    }
  }

  .dropdown-menu {
    left: 0;
    right: 0;
    width: 16.88rem;
    margin: 0;
    background: $gray;
    border: none;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    @media(min-width: 1024px){
      left: auto;
    }
  }
}

.products-sort-order{
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear-all-wrapper{
  width: 100%;
  padding: 0 0 35px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;
  padding: 0;
  font-size: 16px;
  color: $text-dark;
  // border-top: 1px solid $brand-primary;

  >div:first-child {
    line-height: 2.5rem;
  }

  .page-list {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 15px 0;
    margin-bottom: 0;

    li {
      display: inline;
      margin: 0 2px;
    }
  }

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    font-weight: 400;
    color: $text-dark;
    background: transparent;
    border: 1px solid $gray;
    border-radius: 5px;
    &:hover {
      color: $white;
      background: $brand-secondary;
    }
  }

  .previous,
  .next {
    color: $brand-primary;
    background: transparent;
    border: none;
    // border-radius: 0;
    svg{
      width: 30px;
    }
    &:hover,&:focus,&:active{
      background: transparent;
    }
  }

  .disabled {
    color: $text-dark;
  }

  .current a {
    color: $white;
    background: $brand-secondary;
  }
}

.active_filters {
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  background: $gray-light;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
    color: $text-dark;
  }

  ul {
    display: inline;
  }

  .filter-block {
    display: inline-block;
    padding: 0.625rem;
    margin-right: $small-space;
    margin-bottom: $small-space;
    font-size: $font-size-xs;
    color: $text-dark;
    background: $white;

    .close {
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
      font-size: $font-size-lg;
      color: $text-dark;
      opacity: 1;
    }
  }
}

.block-categories {
  display: none;
  padding: 0 0 30px;
  background: $white;
  @media( min-width: 768px ){
    display: block;
  }

  .category-sub-menu {
    margin-top: 10px;

    .category-sub-link {
      font-size: 14px;
    }

    li {
      position: relative;
      padding: 10px 0;

      &[data-depth="1"] {
        // margin-bottom: $small-space;
      }
    }

    li[data-depth="0"] > a {
      display: flex;
      width: 100%;
      padding: 5px 0;
      font-weight: 400;
      background: $white;
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;

      &::before {
        margin-right: 0.3125rem;
        content: "-";
      }
    }
    .category-sub-menu{
      padding: 5px 0;
      padding-left: 15px;
      margin: 0;
    }
    .current-category {
      >a, &[data-depth="0"]>a {
        font-weight: 400;
      }
    }


    &-item{
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
    }
  }

  a {
    color: $text-dark;
  }

  .navbar-toggler{
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    svg{
      width: 18px;
      height: auto;
      color: $text-dark;
    }
  }

  // .collapse-icons {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   padding: 0;
  //   cursor: pointer;

  //   &[aria-expanded="true"] {
  //     .add {
  //       display: none;
  //     }

  //     .remove {
  //       display: block;
  //     }
  //   }

  //   .add,
  //   .remove {
  //     &:hover {
  //       color: $brand-primary;
  //     }
  //   }

  //   .remove {
  //     display: none;
  //   }
  // }

  // .arrows {
  //   .arrow-right,
  //   .arrow-down {
  //     margin-left: 2px;
  //     font-size: $font-size-sm;
  //     cursor: pointer;

  //     &:hover {
  //       color: $brand-primary;
  //     }
  //   }

  //   .arrow-down {
  //     display: none;
  //   }

  //   &[aria-expanded="true"] {
  //     .arrow-right {
  //       display: none;
  //     }

  //     .arrow-down {
  //       display: inline-block;
  //     }
  //   }
  // }
}

.category-bottom{
  color: $text-dark;
}

.left-title{
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 800;
  color: $text-dark;
  padding: 0 0 15px;
}

.facets-title {
  color: $text-dark;
}

.advertising-block {
  margin-bottom: 1.563rem;

  img {
    width: 100%;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #category {
    #left-column {
      // #search_filters_wrapper {
      //   margin-right: -30px;
      //   margin-left: -30px;
      // }

      #search_filter_controls {
        margin-bottom: 1rem;
        text-align: center;

        button {
          margin: 0 0.5rem;
        }
      }

      #search_filters {
        // padding: 0;
        // margin-bottom: 20px;
        // border-top: 1px solid $gray-lighter;
        // box-shadow: none;

        .ui-slider-horizontal {
          margin-bottom: 20px;
        }

        .facet {
          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;

          .title {
            padding: 10px 0;
            align-items: center;
            cursor: pointer;

            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          .facet-title {
            margin: 0;
            color: $text-dark;
          }

          .h6 {
            display: inline-block;
            padding: 1.25rem;
            margin-bottom: 0;
          }

          .navbar-toggler {
            display: inline-block;
            height: inherit;
            padding: 0 0.625rem 0 0;
          }

          .collapse {
            display: none;

            &.in {
              display: block;
            }
          }

          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              // padding: 1.25rem;
              // border-top: 1px solid $gray-lighter;
            }
          }
        }
      }
    }

    #search_filter_toggler {
      width: 100%;
      justify-content: center;
    }
  }

  .products-sort-order {

    .select-title {
      margin-left: 0;
    }
  }

  .products-selection {
    h1 {
      padding-top: 0;
      margin-bottom: 1rem;
      text-align: center;
    }

    .showing {
      padding: 10px 15px 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .products-selection {
    .filter-button {
    }
  }

  #category {
    #left-column {
      // #search_filters_wrapper {
      //   margin-right: -15px;
      //   margin-left: -15px;
      // }
    }
  }
}

@media screen and (max-width: 767px) {
  .category-cover {
    display: none;
  }

  .block-category {
    min-height: 0;
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(md) {
  #products {
    .products {
      justify-content: center;
    }
  }
}
