.subcategories{
  margin: 0 0 10px;

  &-title{
    display: block;
    padding: 0 0 30px;
    margin: 0;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 800;
    color: $text-dark;
  }

  &-list{
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, minmax(0,1fr));

    @media(min-width: 768px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1024px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1280px){
      grid-template-columns: repeat(8, minmax(0,1fr));
    }

    @media(min-width: 1439px){
      grid-template-columns: repeat(8, minmax(0,1fr));
    }
    &-item{
      padding: 0;
      text-align: center;
      img{
        display: inline-block;
        width: 100px;
        height: auto;
        border-radius: 100%;
        border: 1px solid #eee;
      }
      h5{
        margin: 0;
      }
      &-name{
        display: block;
        padding: 10px 0;
        font-size: 16px;
        font-weight: 600;
        color: $text-dark;
      }
    }
  }
}
