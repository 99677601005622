.blog{
  padding: 10vmin 0;
  margin: 0 -15px;
  &-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      gap: 100px;
      grid-template-columns: repeat(2, minmax(0,1fr));
    }
    &-list{
      .article-item-info{
        border-bottom: 2px solid $gray;
        &:last-of-type{
          border-bottom: 2px solid transparent;
        }
      }
    }
    &.grid-4{
      @media(min-width: 1024px) {
        gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }
}

.article {
  padding: 30px 0;

  &-item{
    &-image{
      flex: none;
      width: 100%;
      margin: 0;
      a{
        display: block;
        position: relative;
      }
      img{
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        border-radius: 25px;
      }
    }
    &-info{
      padding: 30px 0;
    }
    &-date{
      display: block;
      padding: 0 0 10px;
      font-size: 13px;
      color: $gray-darker;
    }
    &-title{
      display: block;
      width: 100%;
      margin: 0;
      font-weight: 600;
      font-size: 22px;
      color: $black;
      a{
        color: $black;
      }
    }
    &-desc{
      padding: 20px 0;
    }
  }
  &-detail{
    padding: 3vmin 10vw;
    &-image{
      img{
        display: block;
        width: 100%;
        height: auto;
        margin: 15px 0;
        object-fit: cover;
      }
    }
    &-meta{
      padding: 15px 0 25px;
      text-align: center;
      color: darkgray;
    }
  }
  &-meta{
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 0 10px;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    color: $brand-primary;
    &::before{
      content:'';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 100%;
      background-color: $brand-primary;
    }
    &-date{
      padding: 0 5px;
    }
    &-author{
      padding: 0 5px;
    }
  }
  &-content{
    font-size: 17px;
    line-height: 1.8;
    img{
      max-width: 100%;
      width: 100%;
      height: auto;
      padding: 15px 0;
    }
    h1,h2,h3,h4,h5,h6{
      font-family: $secondary-font;
      font-weight: 600;
      color: #000;
    }
    h1{
      font-size: 28px;
    }
    h2{
      font-size: 25px;
    }
    h3{
      font-size: 22px;
    }
    h4{
      font-size: 19px;
    }
    p{
      font-size: 17px;
      margin: 15px 0;
    }
    ul{
      list-style: disc;
      list-style-position: inside;
      &::marker{
        color: $brand-primary;
      }
    }
    ol{
      list-style: decimal;
      list-style-position: inside;
    }
    p{
      margin: 0;
      padding: 10px 0;
    }
  }
}

@media(min-width: 992px)
{
  .article {
    &-grid{
      // grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.inspiration{
  display: block;
  height: 600px;
  overflow: hidden;
  &-image{
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: .3s all ease-in-out;
    }
    &:hover{
      img{
        transform: scale(1.05);
      }
    }
  }
}