.homecategories{
  padding: 5vmin 0;
  margin: 0 -15px;
  background: $gray;

  &-item{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: calc(100% - 30px);
    padding: 30px;
    margin: 0 0 30px;
    gap: 20px;
    background: $white;
    border-radius: 15px;
    overflow: hidden;
    @media ( min-width: 768px ) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &-image{
      flex: none;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      padding: 10px;
      margin: 0 auto;
      border-radius: 100%;
      background: $gray;
      img{
        flex: none;
        display: block;
        width: auto;
        height: 150px;
      }
    }
    &-info{
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      flex-wrap: wrap;
      &-title{
        display: block;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
      &-desc{
        display: -webkit-box;
        width: 100%;
        margin: 15px 0;
        font-size: 14px;
        font-weight: 400;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        p{
          margin: 0;
        }
      }
    }
  }
}