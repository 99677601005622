.contact-rich {
  margin-bottom: 2rem;
  color: $gray-darker;
  word-wrap: break-word;

  h4 {
    margin-bottom: 2rem;
    color: $gray-darker;
    text-transform: uppercase;
  }

  .block {
    height: auto;
    overflow: hidden;

    .icon {
      float: left;
      width: 3.5rem;

      i {
        font-size: 2rem;
      }
    }

    .data {
      width: auto;
      overflow: hidden;
      font-size: $font-size-sm;
      color: $gray-darker;

      &.email {
        padding-top: 0.375rem;
      }
    }

    a[href^="mailto:"] {
      margin-top: 1rem;
      clear: both;

      @include media-breakpoint-up(sm) {
        font-size: 0.815rem;
      }
    }
  }
}

.contact-form {
  width: 100%;
  padding: 5vmin;
  margin: -100px 0 100px;
  color: $text-dark;
  background: $white;
  border: 1px solid $gray;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  // transform: translateY(-100px);

  &-info{
    padding: 0 0 50px;
    text-align: center;
  }
  &-title{
    position: relative;
    display: block;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 800;
    color: $text-dark;
    line-height: 1;
    z-index: 2;
  }
  &-desc{
    padding: 30px 0 0;
  }

  .form-fields{
    @media( min-width: 767px ){
      padding: 0 10vw;
    }
  }
  .form-footer{
    text-align: center;
  }
}


.contact-info{
  padding: 0 0 50px;
  &-box{
    &-logo{
      margin: 0 0 30px;
      padding: 0;
      img{
        display: block;
        width: 120px;
        height: auto;
      }
    }

    &-shop{
      padding: 20px 0 30px;
      h3{
        font-size: 18px;
        font-weight: 600;
        color: $text-dark;
        text-decoration: none;
        text-transform: none;
      }

      &-item{
        display: flex;
        align-items: center;
        align-content: center;
        gap: 20px;
        padding: 10px 0;
        svg{
          display: block;
          width: 15px;
          height: auto;
          color: $brand-primary;
        }
        &-text{
          a{
            font-weight: 600;
            color: $text-dark;
          }
        }
      }
    }

  }
}