.newsletter{
  &-text{
    font-size: 14px;
  }
  &-form{
    padding: 20px 0;
    @media(min-width: 768px){
      // padding: 20px 25vmin;
    }
  }
  &-input{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
    width: 100%;
    border: 1px solid $gray-darker;
    border-radius: 25px;
    &-group{
      width: 100%;
      input, select{
        display: block;
        width: 100%;
        padding: 15px 25px;
        font-size: 13px;
        background: #fff;
        border: 0;
        border-radius: 25px;
        appearance: none;
        &::placeholder{
          opacity: .5;
          color: $text-dark;
        }
        &:focus{
          outline: none;
        }
      }
    }

  }
  &-submit{
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: auto;
    padding: 15px 50px;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: $dark;
    background: transparent;
    border: 1px solid $dark;
    cursor: pointer;
    transition: .2s all ease-in-out;
    &:disabled{
      opacity: .3;
      cursor:not-allowed
    }
    &:hover{
      color: $white;
      background: $brand-primary;
      border-color: $brand-primary;
    }
  }
  &-conditions{
    padding: 10px 0;
    font-size: 14px;
    color: $text-dark;
    text-align: left;
    p{
      font-size: 14px;
    }
  }
}