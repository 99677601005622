body,
html {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding: 30px;
}

body {
  font-family: $main-font;
  font-size: 16px;
  line-height: 1.25em;
  color: $text-dark;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

///////////////////////////////////////////
//* selection
///////////////////////////////////////////
*::-moz-selection {
  color: $text-dark;
  background: $gray;
}

*::selection {
  color: $text-dark;
  background: $gray;
}

///////////////////////////////////////////
//* scrollbar
///////////////////////////////////////////
::-webkit-scrollbar {
  width: 10px
}

::-webkit-scrollbar-track {
  background: #fafafa
}

::-webkit-scrollbar-thumb {
  background: $brand-primary;
}

::-webkit-scrollbar-thumb:hover {
  filter: brightness(0.8)
}

.small-scroll{
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #fafafa
  }

  &::-webkit-scrollbar-thumb {
    background: $gray;
  }

  &::-webkit-scrollbar-thumb:hover {
    filter: brightness(0.8)
  }
}

img{
  max-width: 100%;
}

ul {
  padding-left: 0;
  list-style: none;
}

a:hover {
  color: $brand-primary;
  text-decoration: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  color: $text-dark;
}

.description{
  line-height: 1.5;
}

.row-r {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

@media only screen and (min-width: 768px){
	.pl-container{
		padding-left: calc((100vW - 768px) / 2);
	}
	.pr-container{
		padding-right: calc((100vW - 768px) / 2);
	}
}
@media only screen and (min-width: 1024px){
	.pl-container{
		padding-left: calc((100vW - 1024px) / 2);
	}
	.pr-container{
		padding-right: calc((100vW - 1024px) / 2);
	}
}
@media only screen and (min-width: 1200px){
	.pl-container{
		padding-left: calc((100vW - 1160px) / 2);
	}
	.pr-container{
		padding-right: calc((100vW - 1160px) / 2);
	}
}
@media only screen and (min-width: 1440px){
	.pl-container{
		padding-left: calc((100vW - 1400px) / 2);
	}
	.pr-container{
		padding-right: calc((100vW - 1400px) / 2);
	}
}
@media only screen and (min-width: 1660px){
	.pl-container{
		padding-left: calc((100vW - 1640px) / 2);
	}
	.pr-container{
		padding-right: calc((100vW - 1640px) / 2);
	}
}

.grid{
  display: grid;
}

.grid-cols-1
{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2
{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2{
  grid-template-rows: repeat(2, minmax(0,1fr));
}

@media(min-width: 992px){
  .md-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md-row-span-2{
    grid-row: span 2/span 2;
  }
  .md-col-span-2{
    grid-column: span 2/span 2;
  }
}

@media(min-width: 1220px){
  .xl-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl-col-span-2{
    grid-column: span 2/span 2;
  }
}

.gap-0{
  gap: 0;
}
.gap-5{
  gap: 5px;
}
.gap-10{
  gap: 10px;
}
.gap-15{
  gap: 15px;
}
.gap-20{
  gap: 20px;
}
.gap-30{
  gap: 30px;
}

.text-center{
  text-align: center;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}

label .color,
.variant-links .color,
.custom-checkbox input[type="checkbox"] + span.color {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  margin: 0.3125rem;
  margin-right: 0.5rem;
  cursor: pointer;
  background-size: contain;
  border: 1px solid $radio-border-color;

  &.active,
  &:hover {
    border: 2px solid $gray-darker;
  }
}

.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 1px solid $radio-border-color;
    }
  }
}

.h1,
.h2,
.h3 {
  color: $gray-darker;
  text-transform: uppercase;
}

.h4 {
  font-weight: $headings-font-weight;
  color: $gray-darker;
}

.card {
  border: 1px solid $gray;
  a{
    color: $black
  }
}


.btn{
  display: inline-flex;
  align-items: center;
  align-content: center;
  min-height: 45px;
  padding: 10px 20px;
  gap: 15px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  transition: .2s all ease-in-out;
  background: transparent;
  border-radius: 25px;
  outline: none;
  &:focus{
    outline: none;
  }

  .material-icons {
    margin-right: $small-space;
  }

  .btn-icon{
    display: flex;
    align-items: center;
    align-content: center;
    width: 30px;
    height: 30px;
    padding: 7px;
    box-sizing: border-box;
    color: $white;
    background-color: $brand-secondary;
    border-radius: 100%;
    transition: .3s all ease-in-out;
    svg{
      flex: none;
      display: block;
      width: 15px;
      height: auto;
    }
  }

  &:hover{
    .btn-icon{
      transform: translateX(5px);
    }
  }
}

.btn-primary{
  color: $text-dark;
  background: transparent;
  border: 1px solid $brand-primary;

  &:hover, &:focus, &:active{
    color: $white;
    background: $brand-primary;
  }
}

.btn-secondary{
  color: $text-dark;
  background: transparent;
  border: 1px solid $brand-secondary;

  &:hover, &:focus, &:active{
    color: $white;
    background: $brand-secondary;
  }
}

.btn-tertiary {
  color: $text-dark;
  background: transparent;
  border: 1px solid $gray-darker;

  &:hover, &:focus, &:active{
    color: $white;
    background: $brand-primary;
  }
}

.btn-white{
  color: $white;
  background: transparent;
  border: 1px solid $white;

  &:hover, &:focus, &:active{
    color: $text-dark;
    background: $white;
  }
}

.btn-unstyle {
  padding: 0;
  text-align: inherit;
  color: $text-dark;
  background-color: transparent;
  border: none;
  &:focus {
    outline: 0;
    .expand-more {
      color: $brand-primary;
    }
  }
}

.btn-cart{
  width: 50px;
  height: 50px;
  justify-content: center;
  padding: 3px;
  color: $white;
  background: $brand-secondary;
  border: 1px solid $brand-secondary;
  .material-icons {
    margin-right: $small-space;
  }
  svg{
    display: block;
    width: 25px;
    height: auto;
    margin: 0;
  }
  &:disabled{
    opacity: .3;
    cursor: not-allowed
  };
  &:hover, &:focus, &:active{
    color: $white;
    background: $brand-secondary;
  }
}

.title{
  padding: 0 0 25px;
  &.text-center{
    text-align: center;
  }
  .upper{
    display: block;
    padding: 0 0 15px;
    font-family: $secondary-font;
    text-transform: uppercase;
    color: $brand-secondary;
    letter-spacing: 8px;
  }
  .main{
    display: block;
    padding: 0;
    font-family: $secondary-font;
    font-weight: 500;
    color: $gray-darker;
    font-size: 30px;
  }
}



label,
.label {
  font-size: 0.875rem;
  color: $text-dark;
  text-align: left;
}

small.label,
small.value {
  font-size: 0.8125rem;
}

.form-control-label {
  padding-top: 0.625rem;
  &-info{
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    padding: 0 2px;
  }
}

.form-control {
  height: 45px;
  padding: 10px 15px;
  color: $text-dark;
  background: $white;
  border: 1px solid $gray-darker;
  border-radius: 25px;

  &:focus {
    color: $text-dark;
    border-color: $brand-primary;
    outline: none;
  }
}

textarea.form-control {
  height: auto;
}

.input-group {
  display: flex;

  &.focus {
    outline: 0.1875rem solid $brand-primary;
  }

  .form-control:focus {
    outline: none;
  }

  .input-group-btn {
    display: block;
    width: auto;

    > .btn {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0.25rem 1rem;
      margin-left: 0;
      font-size: 0.6875rem;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
      border: 0;
      box-shadow: none;

      &[data-action="show-password"] {
        padding: 0.78rem 1rem;
        background: $gray-darker;
      }
    }
  }
}

input,
textarea {
  /* stylelint-disable */
  &::-webkit-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }

  &:-ms-input-placeholder {
    color: $gray;
  }

  &:-moz-placeholder {
    color: $gray;
  }

  &:active {
    &::-webkit-input-placeholder {
      color: $gray-darker;
    }

    &::-moz-placeholder {
      color: $gray-darker;
    }

    &:-ms-input-placeholder {
      color: $gray-darker;
    }

    &:-moz-placeholder {
      color: $gray-darker;
    }
  }
  /* stylelint-enable */
}

.form-control-select {
  height: 2.625rem;
  padding: 0 15px;
  background:
    $white
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=")
    no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
  /* stylelint-disable */
  -moz-appearance: none;
  -webkit-appearance: none;
  /* stylelint-enable */
}

select.form-control-select{
  &:not([size]):not([multiple]){
    height: 45px;
  }
  height: 45px;
}

.form-control-valign {
  padding-top: 0.5rem;
}

.form-control-info {
  display: block;
  padding: 5px 15px 0;
  text-align: left;
}

.form-control-comment {
  padding-top: 0.5rem;
  font-size: 14px;
  color: $text-dark;
}

.form-informations{
  display: block;
  padding: 15px 0 0;
  border-top: 1px solid $gray-darker;
}

.form-control-submit {
  &.disabled {
    color: $white;
    background: $brand-info;
  }
}

.form-group {
  &.has-error {
    input,
    select {
      outline: 0.1875rem solid $brand-danger;
    }

    .help-block {
      color: $brand-danger;
    }
  }
}

.group-span-filestyle {
  label {
    margin: 0;
  }

  .btn-default {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: $white;
    text-transform: uppercase;
    background: $brand-primary;
    border-radius: 0;
  }
}

.bootstrap-touchspin {
  display: flex;
  float: left;
  width: auto;

  input {
    &:focus {
      outline: none;
    }

    &.form-control,
    &.input-group {
      float: left;
      width: 3rem;
      height: $input-height;
      padding: 0.175rem 0.5rem;
      color: $gray-darker;
      background-color: $white;
      border: $input-btn-border-width solid $input-border-color;
    }
  }

  .btn-touchspin {
    @extend .btn-default;
    height: $input-height / 2 + 0.0625rem;
    background-color: $white;
    border: $input-btn-border-width solid $input-border-color;

    &:hover {
      background-color: $gray-light;
    }
  }

  .input-group-btn-vertical {
    float: left;
    width: auto;
    color: $gray-darker;

    .bootstrap-touchspin-up,
    .bootstrap-touchspin-down  {
      border-radius: 0;
      min-height: 20px;
    }

    .touchspin-up {
      &::after {
        content: "\E5CE";
      }
    }

    .touchspin-down {
      &::after {
        content: "\E5CF";
      }
    }

    i {
      top: 0.0625rem;
      left: 0.1875rem;
      font-size: 0.9375rem;
    }
  }
}

.radio-inline {
  display: block;
  padding: 5px 0 0;
  text-align: left;
  .custom-radio {
    margin-right: 0.5rem;
  }

  &:first-of-type {
    margin-right: 1rem;
  }
}

form {
  section {
    .form-informations {
      display: inline-block;
      margin-top: 0.25rem;
      margin-bottom: 1rem;
      color: $gray-darker;

      &-title,
      &-option {
        display: inline-block;
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }
    }
  }

  .form-control-label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: $text-dark;
  }
}

.custom-radio {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
  vertical-align: middle;
  cursor: pointer;
  background: $white;
  border: $gray-darker 1px solid;
  border-radius: 15px;

  input[type="radio"] {
    cursor: pointer;
    opacity: 0;

    &:checked + span {
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      display: block;
      width: 12px;
      height: 12px;
      background-color: $gray-darker;
      border-radius: 15px;
    }

    &:focus + span {
      border-color: $gray-darker;
    }
  }
}

.custom-checkbox {
  position: relative;
  word-break: break-word;

  input[type="checkbox"] {
    position: absolute;
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0;

    + span {
      display: inline-block;
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.5rem;
      vertical-align: middle;
      pointer-events: none;
      cursor: pointer;
      background: $white;
      border: 1px $gray-darker solid;

      .checkbox-checked {
        display: none;
        margin: -0.125rem;
        font-size: 1.25rem;
        color: $gray-darker;
      }
    }

    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }

    &:focus + span {
      border-color: $gray-darker;
    }
  }

  label {
    text-align: left;
  }
}

.text-muted {
  font-size: 0.875rem;
  color: #888!important
}

.done {
  display: inline-block;
  padding: 0 0.8125rem;
  margin-right: 1.563rem;
  color: $brand-success;
}

.thumb-mask {
  > .mask {
    position: relative;
    width: 3.438rem;
    height: 3.438rem;
    margin: $small-space 0;
    overflow: hidden;
    border: $gray-light 1px solid;

    img {
      width: 55px;
      height: 55px;
    }
  }
}

.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: 500;
    }

    dd,
    dt {
      flex: 0 0 45%;
      padding: 0.625rem;
      margin: 0.125rem;
      background: $gray-light;

      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}

.help-block {
  margin-top: $small-space;
}

.btn.disabled,
.btn.disabled:hover {
  // color: $btn-disabled-color;
  // background: $btn-disabled-background;
  opacity: .6;
}

.alert-warning {
  .material-icons {
    padding-top: $extra-small-space;
    margin-right: $small-space;
    font-size: 2rem;
    color: $warning;
  }

  .alert-text {
    padding-top: $small-space;
    font-size: 0.9375rem;
  }

  .alert-link {
    padding: $extra-small-space $medium-space;
    margin-left: $small-space;
    font-size: 0.8125rem;
    font-weight: 600;
    color: $btn-tertiary-color;
    border-width: 2px;
    border-radius: 2px;
  }

  ul li:last-child .alert-link {
    color: $white;
  }

  .warning-buttons {
    margin-top: $extra-small-space;
  }
}

.btn-warning {
  @include transition(all 0.4s ease-in-out);
}

.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-color: transparent;
  background-image: none;
  border: 0.15rem solid $btn-tertiary-color;
  border-color: $btn-tertiary-color;
  @include transition(all 0.4s ease-in-out);

  &:hover {
    color: $btn-tertiary-hover;
    border-color: $btn-tertiary-hover;
  }
}

.alert {
  font-size: 0.8125rem;
}

.nav-item {
  .nav-link,
  .nav-separtor {
    font-weight: 600;
    color: $gray-darker;

    &.active {
      color: $gray-darker;
    }
  }
}

.separator {
  margin: 0;
  border-color: $gray-darker
}

// modal-dialog-centered class added from bootstrap 4.0.0, adapted for bootstrap 4.0.0-alpha.5
.modal-dialog-centered {
  display: flex;
  align-items: center;
  // 30px corresponds to the default margin size from bootstrap 4.0.0-alpha.5 for modal-dialog class
  min-height: calc(100% - (30px * 2));
}

.popover {
  font-family: inherit;
}

input[type="number"] {
  /* stylelint-disable */
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  /* stylelint-enable */
}

.ui-autocomplete.ui-front {
  z-index: 999;
}

.table {
  tr{
    th, td{
      vertical-align: middle;
      color: #444;
      border-color: #cbcbcb;
    }
  }
}

.password-strength-info{
  padding: 5px 15px;
  text-align: left;
}