.search-widget-header {
  form {
    position: relative;
    input[type="text"] {
      height: 40px;
      min-width: 250px;
      width: 100%;
      padding: 10px 20px;
      font-size: 14px;
      border-radius: 25px;
      // border: none;
      outline: none;
      border: 1px solid $gray-darker;
      &::placeholder{
        color: $text-dark;
        opacity: .6;
      }
      &:focus{
        border-color: $brand-secondary;
      }
    }
  }
  button{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    top: 4px;
    right: 5px;
    background: $brand-secondary;
    color: $white;
    border-radius: 999px;
    border: none;
    svg{
      display: inline-block;
      width: 15px;
      height: auto;
    }
  }
}


.ui-autocomplete.searchbar-autocomplete {
  width: 100%;
  min-height: 100%;
  border: none;
}

.ui-autocomplete.searchbar-autocomplete li a,
.ui-autocomplete.searchbar-autocomplete li a.ui-state-focus {
  padding: 8px 15px;
  overflow: auto;
  border: none;
  background: none;
  margin: auto;
  border-radius: 0;
}

.ui-autocomplete.searchbar-autocomplete li a:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.ui-autocomplete.searchbar-autocomplete li a .autocomplete-thumbnail {
  float: left;
  width: 40px;
  height: auto;
  margin-right: 8px;
}
.ui-autocomplete.searchbar-autocomplete li a .product{
  font-size: 14px;
  font-weight: 400;
  color: $text-dark;
}
@media only screen and (min-width: 768px) {
  #search_widget {
    width: auto;
    margin-bottom: 0;
  }

  .ui-autocomplete.searchbar-autocomplete {
    width: 400px;
    min-height: auto;
    left: auto;
  }
}