.about-us{
  position: relative;
  padding: 15vmin 0;
  margin: 0 -15px;
  background: url(../img/webp/ban-pozn-nas.webp) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &-title{
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    &-upper{
      display: block;
      width: 100%;
      padding: 0 0 5px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 7px;
      text-transform: uppercase;
      color: $brand-primary;
    }
    &-main{
      display: block;
      width: 100%;
      font-size: 44px;
      font-weight: 300;
      color: $white;
      text-transform: uppercase;
      strong,b{
        font-weight: 700;
      }
    }
  }
  &-desc{
    display: flex;
    align-items: center;
    align-content: center;
    &-box{
      h1,h2,h3,h4,h5,h6,
      p,ol,ul,strong,b{
        color: $white;}
    }
  }

  &-step{
    padding: 65px 0;
    margin: 0 -15px;
    &-item{
      display: flex;
      align-items: center;
      align-content: center;
      padding: 0 15px;
      @media(min-width: 992px){
        border-right: 1px solid $gray;
        &:last-of-type{
          border-right: none;
        }
      }
      img{
        display: block;
        width: 40px;
        height: auto;
      }
      &-info{
        padding: 0 10px;
        &-desc{
          font-size: 18px;
          color: $black;
        }
      }
    }
  }
}


.company{
  &-title{
    position: relative;
    display: block;
    width: 100%;
    font-size: unquote("clamp(1.6875rem, 1.2188rem + 1.5vw, 2.0625rem)");
    font-weight: 800;
    color: $text-dark;
    z-index: 2;
    &.center{
      text-align: center;
    }
  }

  &-about{
    margin: 0 0 -50px;
    background: $white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    transform: translateY(-100px);
    &-image{
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      overflow: hidden;
      img{
        flex: none;
        display: block;
        width: 100%;
        height: auto;
        border-radius: 25px;
      }
    }
    &-info{
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      padding: 30px;
      height: 100%;
      &-desc{
        padding: 20px 0 0;
        font-size: 16px;
        color: $text-dark;
        line-height: 1.6;
        p{
          font-size: 16px;
          color: $text-dark;
        }
      }
    }
  }

  &-why{
    color: $text-dark;
    &-icons{
      padding: 50px 0;
      &-item{
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 15px;
        &-icon{
          width: 100%;
          margin: 0;
          @media( min-width: 768px){
            width: fit-content;
          }
          img{
            display: block;
            width: 100px;
            height: auto;
          }
        }
        &-text{
          line-height: 1.6;
          strong{
            display: block;
            line-height: 1;
            font-size: 20px;
            color: $text-dark;
          }
        }
      }
    }
  }

  &-about-02{
    padding: 50px 0;
    border-radius: 20px;
    background: $gray;
    &-image{
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &-info{
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      padding: 30px 0;
      height: 100%;
      @media( min-width: 768px){
        padding: 30px;
      }
      &-desc{
        padding: 20px 0 0;
        font-size: 16px;
        color: $text-dark;
        line-height: 1.6;
        p{
          font-size: 16px;
          color: $text-dark;
        }
      }
    }
  }

  &-about-manufactures{
    padding: 100px 0 50px;
  }

  &-about-03{
    padding: 50px 0 0;
    text-align: center;
    // border-bottom: 1px solid $gray-darker;
    &-info{
      padding: 30px 0;
      font-size: 16px;
      color: $text-dark;
      line-height: 1.6;
      @media( min-width: 768px){
        padding: 30px 15vmin;
      }
      p{
        font-size: 16px;
        color: $text-dark;
      }
    }
    &-image{
      margin: 0;
      padding: 0;
      img{
        display: inline-block;
        width: 80%;
        height: auto;
        @media( min-width: 768px){
          width: 60%;
        }
      }
    }
  }

  &-about-04{
    padding: 50px 0;
    border-radius: 20px;
    background: $gray;
    &-image{
      height: 100%;
      overflow: hidden;
      img{
        display: block;
        width: auto;
        height: 100%;
      }
    }
    &-info{
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      padding: 30px 0;
      height: 100%;
      @media( min-width: 768px){
        padding: 30px;
      }
      &-desc{
        padding: 20px 0 0;
        font-size: 16px;
        color: $text-dark;
        line-height: 1.6;
        p{
          font-size: 16px;
          color: $text-dark;
        }
      }
    }
  }
}