.cms{
  &-title{
    padding: 0 0 20px;
    h1{
      position: relative;
      width: fit-content;
      font-size: 37px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      line-height: 1.4;
       &::after{
        content: '';
        display: block;
        min-width: 45%;
        height: 8px;
        background: $brand-primary;
        position: absolute;
        bottom: 6px;
        right: 0;
        transition: .3s all ease-in-out;
        z-index: -1;
      }
    }
  }
}

#custom-text {
  padding: 3.125rem;
  margin-bottom: 1.5rem;
  text-align: center;
  background: $white;
  border-radius: 2px;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: $text-dark;
    text-transform: uppercase;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: $text-dark;

    .dark {
      font-size: 1.125rem;
      font-weight: 600;
      color: $text-dark;
    }
  }

  img {
    max-width: 100%;
    height: 100%;
  }
}

.page-content.page-cms {
  padding: 15px 0;
  color: $text-dark;
  text-align: justify;
  line-height: 1.6;
  background: $white;

  .cms-box {
    img {
      max-width: 100%;
    }
  }
  ol{
    padding: 0;
    padding-left: 25px;
    list-style-position: outside;
    li{
      &::marker{
        color: $brand-primary;
        font-weight: 600;
      }
    }
  }
  ul {
    @extend p;
    padding-left: 25px;
    list-style: disc outside none;
    li{
      &::marker{
        color: $brand-primary;
        font-weight: 600;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}
