.faq{
  &-item{
    display: block;
    margin: 0 0 25px;
    border-radius: 15px;
    background: $gray;

    &-question{
      display: flex;
      align-items: center;
      align-content: center;
      padding: 15px;
      gap: 15px;
      cursor: pointer;

      &-mark{
        flex: none;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-weight: 700;
        font-size: 27px;
        color: $white;
        background: $brand-primary;
        border-radius: 100%;
        text-align: center;
      }
    }

    &-answer{
      padding: 15px 25px 25px;
    }
  }
}