.new-products,
.featured-products,
.specials-products,
.homepage-products{
  position: relative;
  padding: 5vmin 15px;
  margin: 0 -15px;
}
.variant-tooltip {
  display: none;
  position: absolute;
  top: -125px;
  left: -40px;
  width: 100px;
  min-height: 120px;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, .8);
  border: 2px solid $black;
  border-radius: 5px;

  .variant-color {
    width: 96px;
    height: 85px;
    margin: 0 0 5px;

    &.texture {
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .variant-title {
    color: $white;
  }
}
#products,
.featured-products,
.new-products,
.products-same-category,
.product-accessories,
.product-miniature,
.category-products,
.homecontent,
.hfeatured-products {
  .products {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, minmax(0,1fr));

    @media(min-width: 768px){
      grid-template-columns: repeat(2, minmax(0,1fr));
    }

    @media(min-width: 1024px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1280px){
      grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media(min-width: 1439px){
      // grid-template-columns: repeat(5, minmax(0,1fr));
    }
  }

  .product-thumbnail {
    display: block;
  }

  .product-miniature {
    height: 100%;
    padding: 0;
    .product-flags {
      li.product-flag {}
    }
    &:hover{
      .thumbnail-container{
        // box-shadow: 0 20px 20px -16px rgba(0, 0, 0, .5);
      }
    }
  }

  .thumbnail-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: $gray;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 15px;

    .thumbnail-top{
      position: relative;
      display: block;
    }

    .product-thumbnail {
      display: block;
      position: relative;
      img {
        width: 100%;
        height: auto;
        margin: auto;
        background: #fff;
      }
      .second-image{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // display: none;
        opacity: 0;
        transition: .3s all ease-in-out
      }

      &:hover{
        .second-image{
          // display: block;
          opacity: 1;
        }
      }
    }

    .product-action{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 10px 25px;
    }

    .product-description {
      padding: 0 25px 25px;
      border: none;
      // transform: translateY(30px);
      transition: .3s all ease-in-out;
    }

    .thumbnail-title{
      width: 100%;
      display: block;
      padding: 0 0 10px;
      .product-title{
        text-align: left;
        a{
          font-size: 18px;
          font-weight: 600;
          text-transform: none;
        }
      }
    }

    .thumbnail-price{
      text-align: left;
    }

    .thubnail-btn{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 5px;
      width: 100%;
      padding: 15px 0 0;
      transition: .3s all ease-in-out;
    }

    .thumbnail-information-cart{
      width: 100%;
      font-size: 14px;
    }

    .product-title {
      position: relative;
      text-transform: inherit;
      padding: 0;
      text-align: center;

      a {
        display: block;
        line-height: 1.4;
        font-family: $secondary-font;
        font-size: 14px;
        color: $text-dark;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
      }
    }

    .product-price-and-shipping {
      padding: 5px 0;
      font-size: 14px;
      color: $text-dark;
      // font-family: $secondary-font;
      font-weight: 400;
      text-align: left;

      .discount-product {
        display: none;
      }
      .regular-price {
        text-decoration: line-through;
        font-size: 14px;
        display: block;
        width: 100%;
      }

      .price{
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: $text-dark;
      }
      .price-discount{
        display: block;
        width: 100%;
        font-weight: 400;
        color: $text-dark;
      }
      .price.without-tax{
        display: block;
        width: 100%;
        font-size: 14px;
        color: $gray-dark;
      }
    }

    // .add-to-cart-box{
    //   display: flex;
    //   align-items: center;
    //   align-content: center;
    //   justify-content: center;

    //   .fast-cart-qty{
    //     width: 40px;
    //     height: 40px;
    //     padding: 5px;
    //     font-size: 14px;
    //     text-align: center;
    //     box-sizing: border-box;
    //     border: 0;
    //     background-color: #f5f5f5;
    //   }

    //   .qty-text{
    //     display: inline-block;
    //     padding: 0 10px;
    //     font-size: 14px;
    //   }

    // }

    &:hover{
      .product-description{
        transform: translateY(0);
      }
      .thubnail-btn{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .products-section-title {
    text-align: center;
  }



  .thumbnail-container {
    // overflow: hidden;
    transition: .3s all ease-in-out;
    &:hover {

      .product-buttons {
        bottom: 15px;
      }
    }
  }

  .product-reference {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: #C1BFBF;
  }

  .product-category {
    color: $brand-primary;
  }

  .product-availability {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
    color: $gray;
  }



  .comments_note {
    text-align: center;
    color: $gray;
  }

  .count {
    color: $text-dark;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }

  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .product-miniature-list {
    display: flex;
    flex-wrap: wrap;

    .thumbnail-container {
      width: 50%;
    }

    .product-description {
      padding: 20px;
      width: 50%;
    }
  }
  .variant-links{
    .color{
      position: relative;
      width: 12px;
      height: 12px;
      margin: 2px;
      border-radius: 100%;
      border: 2px solid $white;
      &:first-of-type{
        outline: 1px solid $black;
      }
      &:hover{
        outline: 1px solid $black;
      }
    }
  }

  .product-information{
    padding:10px 0;
    font-size: 15px;
  }
}

@include media-breakpoint-down(md) {
  .featured-products {
    .products {
      justify-content: center;
    }
  }
}



.product-energy-class {
  position: relative;
  z-index: 10;
}

.energyclass {
  position: relative;
  display: inline-block;
  img{
    display: block;
    width: 50px;
    height: auto;
  }
}