#main {
  .page-header {
    margin-bottom: 1.563rem;
  }

  .page-content {
    margin-bottom: 1.563rem;

    h6 {
      margin-bottom: 1.125rem;
    }

    #notifications {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .page-footer {
    margin-bottom: 1.563rem;
  }
}
