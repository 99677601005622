.social{
  &-btn{
    padding: 0 10px;
  }
  &-link{
    display: inline-block;
    cursor: pointer;
  }
  &-icon{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #d0d4d4;
    border-radius: 100%;
    &:hover{
      border-color: $brand-primary;
    }
    img{
      width: auto;
      height: 15px;
    }
  }
}
.block-social {
  width: 100%;
  padding: 15px 0;

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      display: inline-block;
      a {
        display: block;
        width: 15px;
        height:15px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: .3s all ease-in-out;
        &:hover {
          transform: translateY(-3px);
          color: $white
        }
      }
    }
  }
}

.facebook {
  background-image: url("../icons/facebook.svg");
}

.twitter {
  background-image: url("../icons/twitter.svg");
}

.linkedin {
  background-image: url("../icons/linkedin.svg");
}

.rss {
  background-image: url("../icons/rss.svg");
}

.youtube {
  background-image: url("../icons/youtube.svg");
}

.googleplus {
  background-image: url("../icons/gplus.svg");
}

.pinterest {
  background-image: url("../icons/pinterest.svg");
}

.vimeo {
  background-image: url("../icons/vimeo.svg");
}

.instagram {
  background-image: url("../icons/instagram.svg");
}

.tiktok {
  background-image: url("../icons/tiktok.svg");
}