.compare{
  &-product{
    position: relative;
    height: calc( 100% - 30px);
    margin:  0 0 30px;
    background: $gray;
    border: 1px solid $gray;

  }
  &-products{
    overflow: auto;
    &-delete {
      display: block;
      text-align: center;

      &-btn {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 30px;
        height: 30px;
        color: $white;
        background: $brand-secondary;
        border: none;
        border-radius: 100%;
        cursor: pointer;
      }
    }
    &-title{
      display: block;
      font-size: 22px;
      font-weight: 600;
      padding: 15px 0;
      margin: 0;
    }
    &-grid{
      display: grid;
      grid-template-columns: repeat(1, 300px);
      grid-auto-flow: column;
      margin: 0 0 30px;
      border-top: 1px solid $gray;
      &-item{
        min-width: 400px;
        &:first-of-type{
          min-width: 300px;
        }
      }
    }
    &-image{
      height: 350px;
      padding: 0;
      margin: 0;
      img{
        width: 100%;
        height: 350px;
        object-fit: contain;
        padding: 5px;
      }
    }
    &-info,
    &-feature{
      min-height: 70px;
      display: flex;
      align-items: center;
      align-content: center;
      padding: 5px;
      font-size: 15px;

      &:nth-child(odd){
        background-color: #f7f7f7;
      }
    }
  }
  &-footer{
    &-link{
      position: fixed;
      bottom: 100px;
      right: 18px;
      width: 50px;
      height: 50px;
      padding: 10px;
      background: $brand-secondary;
      border-radius: 100%;
    }
    &-icon{
      width: 30px;
      height: auto;
      color: $white;
    }
    &-count {
      position: absolute;
      right: -5px;
      top: -5px;
      width: 22px;
      height: 22px;
      text-align: center;
      font-size: 11px;
      color: $text-dark;
      border: 1px solid #eee;
      border-radius: 100%;
      background: #fff;
    }
  }
  &-alert {
    position: fixed;
    bottom: 170px;
    right: 20px;
    &-success {
      display: none;
      padding: 10px 15px;
      background: green;
      font-size: 14px;
      border-radius: 5px;
      color: #fff;
      &.is-active{
        display: block;
      }
    }
  }
}