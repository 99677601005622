.manufacturers{
  padding: 30px 0 10px;
  &-list{
    position: relative;
    .swiper-navs{
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      z-index: 5;
      .btn-manufacturers-button-prev{
        position: absolute;
        left: 0;
        &.swiper-button-disabled{
          opacity: .3;
        }
      }
      .btn-manufacturers-button-next{
        position: absolute;
        right: 0;
        &.swiper-button-disabled{
          opacity: .3;
        }
      }
    }

  }
  &-item{
    display: block;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    img{
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }
}